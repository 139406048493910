<template>
  <div class="activityarea">
    <div class="areabox l">
      <div class="header">
        <h2>限时秒杀</h2>
        <span class="icon"><i></i></span>
      </div>
      <div class="listarea">
        <div class="listbox l">
          <img class="photo" src="@/assets/img/midnav/nav1.png" />
          <div class="infobox">
            <div class="flag">预计到手</div>
            <div class="expect">
              <span>&yen;</span>
              78.8
            </div>
          </div>
        </div>
        <div class="listbox r">
          <img class="photo" src="@/assets/img/midnav/nav1.png" />
          <div class="infobox">
            <div class="flag">预计到手</div>
            <div class="expect">
              <span>&yen;</span>
              78.8
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="areabox r">
      <div class="header">
        <h2>生鲜好券</h2>
        <span class="icon"><i></i></span>
      </div>
      <div class="listarea">
        <div class="listbox l">
          <img class="photo" src="@/assets/img/midnav/nav1.png" />
          <div class="ticketbox">
            <i class="cl"></i>
            <i class="cr"></i>
            <div class="flag">满99减10</div>
            <div class="pickup">
              <span>&yen;</span>
              78.8
            </div>
          </div>
        </div>
        <div class="listbox r">
          <img class="photo" src="@/assets/img/midnav/nav1.png" />
          <div class="ticketbox">
            <i class="cl"></i>
            <i class="cr"></i>
            <div class="flag">满99减10</div>
            <div class="pickup">
              <span>&yen;</span>
              78.8
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="activityarea">
    <div class="areabox l">
      <div class="header">
        <h2>限时秒杀</h2>
        <span class="icon"><i></i></span>
      </div>
      <div class="listarea">
        <div class="listbox l">
          <img class="photo" src="@/assets/img/midnav/nav1.png" />
          <div class="infobox">
            <div class="flag">预计到手</div>
            <div class="expect">
              <span>&yen;</span>
              78.8
            </div>
          </div>
        </div>
        <div class="listbox r">
          <img class="photo" src="@/assets/img/midnav/nav1.png" />
          <div class="infobox">
            <div class="flag">预计到手</div>
            <div class="expect">
              <span>&yen;</span>
              78.8
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="areabox r">
      <div class="header">
        <h2>生鲜好券</h2>
        <span class="icon"><i></i></span>
      </div>
      <div class="listarea">
        <div class="listbox l">
          <img class="photo" src="@/assets/img/midnav/nav1.png" />
          <div class="ticketbox">
            <i class="cl"></i>
            <i class="cr"></i>
            <div class="flag">满99减10</div>
            <div class="pickup">
              <span>&yen;</span>
              78.8
            </div>
          </div>
        </div>
        <div class="listbox r">
          <img class="photo" src="@/assets/img/midnav/nav1.png" />
          <div class="ticketbox">
            <i class="cl"></i>
            <i class="cr"></i>
            <div class="flag">满99减10</div>
            <div class="pickup">
              <span>&yen;</span>
              78.8
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MemberActivities',
  setup() {},
}
</script>

<style lang="less" scoped>
.activityarea {
  width: 7.5rem;
  background-color: #f6f6f6;
  padding: 0.2rem;
  box-sizing: border-box;
  overflow: hidden;
  .areabox {
    width: 3.45rem;
    height: 2.9rem;
    padding: 0.2rem;
    border-radius: 0.2rem;
    box-sizing: border-box;
    background-color: #fff;
    .header {
      width: 100%;
      height: 0.35rem;
      h2 {
        float: left;
        font-size: 0.34rem;
        line-height: 0.35rem;
        color: #333;
        width: 2.34rem;
      }
      .icon {
        float: right;
        width: 0.35rem;
        height: 0.35rem;
        border-radius: 0.175rem;
        display: block;
        background-color: #e4e5ec;
        i {
          display: block;
          width: 0.12rem;
          height: 0.12rem;
          border: none;
          border-right: 0.04rem solid #333;
          border-bottom: 0.04rem solid #333;
          margin: 0.1rem 0 0 0.08rem;
          transform: rotate(315deg);
        }
      }
    }
    .listarea {
      width: 3.05rem;
      height: 2.1rem;
      margin-top: 0.04rem;
      .listbox {
        width: 1.4rem;
        height: 2.1rem;
        .photo {
          display: block;
          width: 1rem;
          height: 1rem;
          margin: 0.07rem auto;
        }
        .infobox {
          width: 1.4rem;
          height: 0.95rem;
          overflow: hidden;
          .flag {
            font-size: 0.24rem;
            height: 0.3rem;
            line-height: 0.3rem;
            margin: 0.14rem auto;
            text-align: center;
          }
          .expect {
            font-size: 0.22rem;
            font-weight: 600;
            height: 0.35rem;
            line-height: 0.35rem;
            text-align: center;
            border-radius: 0.2rem;
            color: #fff;
            background: linear-gradient(90deg, #e79997, #e62020);
          }
        }
        .ticketbox {
          position: relative;
          border-radius: 0.05rem;
          margin-top: 0.05rem;
          width: 1.4rem;
          height: 0.9rem;
          background-color: #f9c6c6;
          overflow: hidden;
          float: left;
          .cl {
            position: absolute;
            top: 0.33rem;
            transform: translateX(-50%);
            display: block;
            width: 0.1rem;
            height: 0.1rem;
            border-radius: 0.1rem;
            background-color: #fff;
          }
          .cr {
            position: absolute;
            top: 0.33rem;
            right: 0;
            transform: translateX(50%);
            display: block;
            width: 0.1rem;
            height: 0.1rem;
            border-radius: 0.1rem;
            background-color: #fff;
          }
          .flag {
            height: 0.38rem;
            font-size: 0.2rem;
            font-weight: 600;
            line-height: 0.38rem;
            text-align: center;
            color: #f74008;
            border-bottom: 0.02rem dashed #fff;
          }
          .pickup {
            font-size: 0.22rem;
            font-weight: 600;
            width: 1.2rem;
            height: 0.35rem;
            line-height: 0.35rem;
            text-align: center;
            margin: 0.06rem auto;
            border-radius: 0.2rem;
            color: #fff;
            background: linear-gradient(90deg, #f74008, #e62020);
          }
        }
      }
      .l {
        float: left;
      }
      .r {
        float: right;
      }
    }
  }
  .l {
    float: left;
  }
  .r {
    float: right;
  }
}
</style>
