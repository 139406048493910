import { actionURL } from '@/config/constInfo'
class Wxuser {
  constructor(requestFrame) {
    this.$http = requestFrame
  }

  async gzhLogin(code, shareid = 0) {
    try {
      const rst = await this.$http.get(actionURL.wxgzhlogin, {
        params: { code, shareid, from: actionURL.platform },
      })
      return rst.data
    } catch (err) {
      return null
    }
  }

  async stoken(data) {
    try {
      const rst = await this.$http.post(actionURL.wxgzhstoken, data)
      return rst.data
    } catch (err) {
      return null
    }
  }

  async wxmineprofile() {
    try {
      const rst = await this.$http.post(actionURL.wxmineprofile, {})
      return rst.data
    } catch (err) {
      return null
    }
  }

  async WxJSAPIConf(pageurl) {
    const rst = await this.$http.get(actionURL.wxjsapiconf, {
      params: {
        pageurl,
      },
    })
    return rst.data
  }
}

export default Wxuser
