import { actionURL } from '@/config/constInfo'
class Ware {
  constructor(requestFrame) {
    this.$http = requestFrame
    this.config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  }

  async getCategoryList() {
    try {
      const rst = await this.$http.get(actionURL.categoryList)
      return rst.data
    } catch (err) {
      return null
    }
  }

  async getHotSalesList() {
    try {
      const rst = await this.$http.get(actionURL.hotsalesList)
      return rst.data
    } catch (err) {
      return null
    }
  }

  async getWareInfo(id) {
    try {
      const rst = await this.$http.get(actionURL.wareinfo, {
        params: { id: id, from: actionURL.platform },
      })
      return rst.data
    } catch (err) {
      return null
    }
  }

  async addshoppingcart(data) {
    try {
      const rst = await this.$http.post(actionURL.addshoppingcart, data)
      return rst.data
    } catch (err) {
      return null
    }
  }

  async getWareSearch(data) {
    try {
      const rst = await this.$http.post(actionURL.waresearch, data)
      return rst.data
    } catch (err) {
      return null
    }
  }

  async getShoppingCart() {
    try {
      const rst = await this.$http.get(actionURL.shoppingcartlist)
      return rst.data
    } catch (err) {
      return null
    }
  }
  async delShoppingCart(data) {
    try {
      const rst = await this.$http.post(actionURL.shoppingcartdel, data)
      return rst.data
    } catch (err) {
      return null
    }
  }

  //本地生活
  async localLife(data) {
    try {
      const rst = await this.$http.post(actionURL.localLife, data)
      return rst.data
    } catch (err) {
      return null
    }
  }

  //分类产品列表
  async CateGoods(data) {
    try {
      const rst = await this.$http.post(actionURL.categoods, data)
      return rst.data
    } catch (err) {
      return null
    }
  }
}

export default Ware
