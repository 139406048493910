import { actionURL } from '@/config/constInfo'
class Order {
  constructor(requestFrame) {
    this.$http = requestFrame
  }

  async orderCreate(data) {
    const rst = await this.$http.post(actionURL.orderCreate, data)
    return rst.data
  }

  async orderCreateForCart(data) {
    const rst = await this.$http.post(actionURL.orderCreateForCart, data)
    return rst.data
  }

  async orderInfo(orderno) {
    try {
      const rst = await this.$http.get(actionURL.orderInfo, {
        params: { orderno, from: actionURL.platform },
      })
      return rst.data
    } catch (err) {
      return null
    }
  }

  async orderConfirm(data) {
    try {
      const rst = await this.$http.post(actionURL.orderConfirm, data)
      return rst.data
    } catch (err) {
      return null
    }
  }

  async orderMyList(data) {
    try {
      const rst = await this.$http.post(actionURL.orderMyList, data)
      return rst.data
    } catch (err) {
      return null
    }
  }

  async orderDetail(orderno) {
    try {
      const rst = await this.$http.get(actionURL.orderDetail, {
        params: { orderno, from: actionURL.platform },
      })
      return rst.data
    } catch (err) {
      return null
    }
  }

  async GetPackageList(orderno) {
    try {
      const rst = await this.$http.get(actionURL.orderExpressinfo, {
        params: { orderno, from: actionURL.platform },
      })
      return rst.data
    } catch (err) {
      return null
    }
  }

  async getExpressSetp(id) {
    try {
      const rst = await this.$http.get(actionURL.getExpressSetp, {
        params: { id, from: actionURL.platform },
      })
      return rst.data
    } catch (err) {
      return null
    }
  }

  async balanceRecharge(data) {
    const rst = await this.$http.post(actionURL.balanceRecharge, data)
    return rst.data
  }

  async balanceLogList() {
    try {
      const rst = await this.$http.post(actionURL.balanceLogList, {})
      return rst.data
    } catch (err) {
      return null
    }
  }

  async defaultFreight() {
    try {
      const rst = await this.$http.get(actionURL.defaultFreight, {
        params: { from: actionURL.platform },
      })
      return rst.data
    } catch (err) {
      return null
    }
  }
}

export default Order
