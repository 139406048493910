import { actionURL } from '@/config/constInfo'
class Coupon {
  constructor(requestFrame) {
    this.$http = requestFrame
  }

  async wareSCoupon(id) {
    try {
      const rst = await this.$http.get(actionURL.warecouponlist, {
        params: { id },
      })
      return rst.data
    } catch (err) {
      return null
    }
  }

  async pickingCoupon(id) {
    try {
      const rst = await this.$http.post(actionURL.pickingCoupon, { id })
      return rst.data
    } catch (err) {
      return null
    }
  }

  async myCoupons() {
    try {
      const rst = await this.$http.get(actionURL.mycoupons, {
        params: {},
      })
      return rst.data
    } catch (err) {
      return null
    }
  }
}

export default Coupon
