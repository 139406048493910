<template>
  <div class="superpower">
    <h2>爆款推荐</h2>
    <div class="collbox">
      <van-swipe :loop="false" :show-indicators="false">
        <van-swipe-item v-for="(data, idx) in renderData" :key="idx">
          <div class="item" v-for="item in data" :key="item.Id">
            <div class="node" @click="myUrlTo(item.H5Link)">
              <img class="thumb" :src="item.ImgPath" alt="" srcset="" />
              <div class="price">
                <van-icon name="fire" />
                &yen;{{ item.Remark }}
              </div>
              <div class="mark">{{ item.Title }}</div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { ref, onUpdated, onMounted } from 'vue'
import { jumUrl } from '@/unit/common'
export default {
  name: 'SuperPower',
  props: {
    inputData: Object,
  },
  setup(props) {
    const renderData = ref([])
    const router = useRouter()
    const origindataTrans = () => {
      let _origindata = props.inputData

      if (_origindata) {
        let flag = 0
        let newarr = null
        for (let i = 0; i < _origindata.length; i++) {
          if (flag == 0) {
            newarr = null
            newarr = []
          }
          newarr.push(_origindata[i])
          flag++
          if (flag == 4 || i == _origindata.length - 1) {
            let temp = []
            Object.assign(temp, newarr)
            renderData.value.push(temp)
            flag = 0
          }
        }
      }
    }
    const myUrlTo = (urlpath) => {
      jumUrl(urlpath, router)
    }
    onUpdated(() => {
      origindataTrans()
    })
    onMounted(() => {
      origindataTrans()
    })
    return {
      renderData,
      myUrlTo,
    }
  },
}
</script>
<style scoped lang="less">
.superpower {
  width: 7.5rem;
  min-height: 2rem;
  background-color: #fff;
  border-radius: 0.3rem;
  box-sizing: border-box;
  padding: 0.2rem;
  h2 {
    font-size: 0.34rem;
    font-weight: 600;
    line-height: 0.5rem;
  }
  .collbox {
    height: 2.8rem;
    margin-top: 0.1rem;

    .item {
      width: 25%;
      height: 2.6rem;
      padding-top: 0.1rem;
      float: left;
      .node {
        width: 1.5rem;
        height: 2.6rem;
        margin: 0 auto;

        .thumb {
          width: 1.5rem;
          height: 1.5rem;
        }
        .price {
          width: 1.5rem;
          height: 0.4rem;
          line-height: 0.4rem;
          font-size: 0.3rem;
          text-align: center;
          color: #fff;
          font-weight: 600;
          margin-top: 0.15rem;
          border-radius: 0.08rem;
          background-color: #f9506a;
        }
        .mark {
          width: 1.5rem;
          height: 0.4rem;
          line-height: 0.4rem;
          font-size: 0.26rem;
          text-align: center;
          font-weight: 600;
          margin-top: 0.15rem;
          color: #f9506a;
        }
      }
    }
  }
}
</style>
