import { actionURL } from '@/config/constInfo'
class AdvertLink {
  constructor(requestFrame) {
    this.$http = requestFrame
  }

  async getList(position) {
    try {
      const rst = await this.$http.get(actionURL.advertlist, {
        params: { position, from: actionURL.platform },
      })
      return rst.data
    } catch (err) {
      return null
    }
  }

  async getSinglePage(guid) {
    try {
      const rst = await this.$http.get(actionURL.getSpageInfo, {
        params: { guid },
      })
      return rst.data
    } catch (err) {
      return null
    }
  }
}

export default AdvertLink
