<template>
  <div class="midnav">
    <router-link
      class="navto"
      :to="'/category/' + item.Id"
      v-for="item in categorylist"
      :key="item.Id"
    >
      <img class="icon" :src="item.Icon" />
      <span class="navtitle">{{ item.Alias }}</span>
    </router-link>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { webApi, apiCode } from '@/api'
const categorylist = ref([])
const getCatepory = async () => {
  const apidata = await webApi.Ware.getCategoryList()
  if (apidata.code == apiCode.success) {
    categorylist.value = apidata.data
  }
}
onMounted(async () => {
  await getCatepory()
})
</script>
<script>
export default {
  name: 'MidNavd',
}
</script>
<style lang="less" scoped>
.midnav {
  width: 7.5rem;
  min-height: 1.5rem;
  background-color: #fff;
  overflow: hidden;
  .navto {
    width: 1.875rem;
    height: 1.75rem;
    text-align: center;
    display: block;
    float: left;
    .icon {
      display: block;
      width: 1rem;
      height: 1rem;
      margin: 0.15rem auto;
    }
    .navtitle {
      font-size: 0.24rem;
      height: 0.4rem;
      display: block;
      color: #666;
    }
  }
}
</style>
