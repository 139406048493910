import { actionURL } from '@/config/constInfo'
class Project {
  constructor(requestFrame) {
    this.$http = requestFrame
  }

  async createAddress(data) {
    try {
      const rst = await this.$http.post(actionURL.addressCreate, data)
      return rst.data
    } catch (err) {
      return null
    }
  }

  async GetMyAddress() {
    try {
      const rst = await this.$http.get(actionURL.addressList)
      return rst.data
    } catch (err) {
      return null
    }
  }
  async modifyAddress(data) {
    try {
      const rst = await this.$http.post(actionURL.addressModify, data)
      return rst.data
    } catch (err) {
      return null
    }
  }

  async delAddress(id) {
    try {
      const rst = await this.$http.post(actionURL.addressDel, { id: id })
      return rst.data
    } catch (err) {
      return null
    }
  }

  async logVisitor(data) {
    try {
      const rst = await this.$http.post(actionURL.logVisitor, data)
      return rst.data
    } catch (err) {
      return null
    }
  }
}

export default Project
