import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { whiteListOfLogRecode, cookieKey } from '@/config/constInfo'

import Api from './api'
import VantLoad from './config/vantLoad'
import store from './store'
import { webApi, apiCode } from '@/api'
import { getCookie, getValFromUrlStr } from '@/unit/common'
import { nanoid } from 'nanoid'
const app = createApp(App)
app.use(store)
app.use(Api)
app.use(router)
app.use(VantLoad)

router.beforeEach(async (to) => {
  window.document.title = to.meta.title == undefined ? '--' : to.meta.title

  if (whiteListOfLogRecode.includes(to.path)) {
    let visitorident = window.localStorage.getItem(cookieKey.visitorIdent)
    if (!visitorident) {
      visitorident = nanoid()
      window.localStorage.setItem(cookieKey.visitorIdent, visitorident)
    }
    webApi.Profile.logVisitor({ page: to.fullPath, visitorident })
  }

  if (!store.state.loginstatus) {
    //地址栏进入时，有sltoken尝试静默录陆
    const logintoken = getCookie(cookieKey.sltoken)
    if (logintoken) {
      const rstdata = await webApi.Wxuser.stoken({ stk: logintoken })
      if (rstdata.code == apiCode.success) {
        store.commit('loginsuccess', rstdata.data)
      }
    }
  }

  let shareid = getValFromUrlStr(to.fullPath, 'shareid')
  if (shareid) {
    //地址栏有shareid,localStorage没有shareid,则把shareid存入localStorage
    const ls_shareid = window.localStorage.getItem(cookieKey.ck_shareid)
    if (!ls_shareid) {
      window.localStorage.setItem(cookieKey.ck_shareid, shareid)
    }
  }

  if (to.meta.requiresAuth && !store.state.loginstatus) {
    return {
      path: '/login',
      query: { redirect: to.fullPath },
    }
  }
  store.commit('pushCurrentPath', to.path)
})
app.mount('#app')
