import { createStore } from 'vuex'
export default createStore({
  state: {
    //用户是否登陆
    loginstatus: false,
    //用户信息
    userinfo: {},
    currentpath: '',
    addressInfo: {},
    addressList: [],
    shoppingCartList: [],
    categoryList: [],
    mycoupons: [],
    //团长基本数据统计
    baseStatis: {},
    //团队成员
    teamTotal: 0,
    teamNextIdx: 1,
    teamNomore: 0,
    teamList: [],
    //团队成员
    //佣金明细
    chargeTotal: 0,
    chargeNextIdx: 1,
    chargeNomore: 0,
    chargeList: [],
    //佣金明细
    //本地生活
    lifeNextIdx: 1,
    lifeNomore: 0,
    lifeList: [],
    //本地生活
  },
  getters: {},
  mutations: {
    loginsuccess(state, payload) {
      state.loginstatus = true
      state.userinfo = payload
    },
    loginFailed(state) {
      state.loginstatus = false
      state.userinfo = {}
    },
    pushCurrentPath(state, payload) {
      state.currentpath = payload
    },
    pushAddressInfo(state, payload) {
      state.addressInfo = payload
    },
    pushAddressList(state, payload) {
      state.addressList = state.addressList.concat(payload)
    },
    pushCategoryData(state, payload) {
      state.categoryList = payload
    },
    pushMycoupons(state, payload) {
      state.mycoupons = state.mycoupons.concat(payload)
    },
    rushMycoupons(state, payload) {
      state.mycoupons = payload
    },
    updateAddressInList(state, payload) {
      let temp = state.addressList.find((x) => x.id == payload.id)
      for (const key in temp) {
        if (temp.hasOwnProperty.call(temp, key)) {
          temp[key] = payload[key]
        }
      }
    },
    delFromAddressList(state, payload) {
      state.addressList = state.addressList.filter((x) => x.id != payload)
      if (state.addressInfo.id == payload) {
        state.addressInfo = {}
      }
    },
    pushShoppingCartList(state, payload) {
      state.shoppingCartList = state.shoppingCartList.concat(payload)
    },

    delFromShoppingCartList(state, payload) {
      state.shoppingCartList = state.shoppingCartList.filter((x) => x.Id != payload)
    },
    pushBaseStatis(state, payload) {
      state.baseStatis = payload
    },
    pushTeamList(state, payload) {
      state.teamTotal = payload.total
      state.teamNextIdx = state.teamNextIdx + 1
      state.teamList = state.teamList.concat(payload.data)
    },
    markTeamNomore(state) {
      state.teamNomore = 1
    },
    pushChargeList(state, payload) {
      state.chargeTotal = payload.total
      state.chargeNextIdx = state.chargeNextIdx + 1
      state.chargeList = state.chargeList.concat(payload.data)
    },
    markChargeNomore(state) {
      state.chargeNomore = 1
    },
    pushLifeList(state, payload) {
      state.lifeNextIdx = state.lifeNextIdx + 1
      state.lifeList = state.lifeList.concat(payload.data)
    },
    markLifeNomore(state) {
      state.lifeNomore = 1
    },
  },
  actions: {},
  modules: {},
})
