import { actionURL } from '@/config/constInfo'
class SDP {
  constructor(requestFrame) {
    this.$http = requestFrame
    this.config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  }

  //基本数据统计
  async mineBaseStatis(data) {
    try {
      const rst = await this.$http.post(actionURL.sdpBasestatis, data)
      return rst.data
    } catch (err) {
      return null
    }
  }

  //我的团队成员
  async mineTeam(data) {
    try {
      const rst = await this.$http.post(actionURL.sdpTeam, data)
      return rst.data
    } catch (err) {
      return null
    }
  }

  //我的所有的佣金列表
  async mineAllCharge(data) {
    try {
      const rst = await this.$http.post(actionURL.sdpMycharges, data)
      return rst.data
    } catch (err) {
      return null
    }
  }

  //申请佣金提现
  async doCashing(data) {
    try {
      const rst = await this.$http.post(actionURL.sdpDoCashing, data)
      return rst.data
    } catch (err) {
      return null
    }
  }

  //佣金统计
  async MyChargesStatis() {
    try {
      const rst = await this.$http.post(actionURL.sdpMyChargesStatis, {})
      return rst.data
    } catch (err) {
      return null
    }
  }

  //修改支付宝账号
  async modifyAlipayInfo(data) {
    try {
      const rst = await this.$http.post(actionURL.sdpModifyAlipayInfo, data)
      return rst.data
    } catch (err) {
      return null
    }
  }

  //我的提现记录
  async myCashingRecord(data) {
    try {
      const rst = await this.$http.post(actionURL.sdpCashingRecord, data)
      return rst.data
    } catch (err) {
      return null
    }
  }

  async applyDistribution(data) {
    try {
      const rst = await this.$http.post(actionURL.distributionApply, data)
      return rst.data
    } catch (err) {
      return null
    }
  }
}

export default SDP
